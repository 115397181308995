/* eslint-disable */
import * as Sentry from '@sentry/nuxt';

const environment =
  process.env.NUXT_PUBLIC_NODE_ENV === 'preview' ? 'staging' : 'production';

Sentry.init({
  // If set up, you can use your runtime config here
  // dsn: useRuntimeConfig().public.sentry.dsn,
  dsn: 'https://22a529dcc6cbbbd315decbbc98e20bc7@o4508217646120960.ingest.de.sentry.io/4508217647693904',
  integrations: [
    Sentry.replayIntegration({
      // Additional SDK configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true,
    }),
    // Sentry.feedbackIntegration({
    //   colorScheme: "dark",
    //   showBranding: false,
    //   showName: false,
    //   showEmail: false,
    // }),
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
  ],
  release: 'live-for-today-website@' + process.env.VERSION,
  // Tracing
  // We recommend adjusting this value in production, or using a tracesSampler for finer control.
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/live-for-today\.com/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  // This function will be called for every sampled span
  // to determine if it should be profiled
  profilesSampler: (samplingContext) => {
    return 1.0;
  },
  environment: environment,
});
